
import { Component, Vue } from 'vue-property-decorator';


const routeGuardWound = async (to, from, next) => {
    next();
};

@Component
export default class Wound extends Vue {
  public beforeRouteEnter(to, from, next) {
    routeGuardWound(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardWound(to, from, next);
  } 
}
